.btn-primary:enabled {
  background-color: var(--color-secondary) !important;
  color: #ffffff !important;
}
.btn-primary:hover {
  background-color: var(--color-tertiary-hover) !important;
}

.btn-primary:disabled {
  background-color: #cccccc !important;
  color: #999999 !important;
}
.btn-primary {
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-secondary {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
}

.btn-secondary:hover {
  background-color: white !important;
  color: rgb(66, 66, 66) !important;
  border: 1px solid black !important;
}

.btn-secondary:disabled {
  background-color: #cccccc !important;
  color: #999999 !important;
}
