.nuclea-icon {
  color: var(--color-info-table) !important;
  font-size: 1.4em !important;
  margin-top: 0px !important;
  margin-left: 5px !important;
}
.nuclea-icon:hover {
  cursor: pointer !important;
}

.empty-state {
  text-align: center;
  padding: 2em;
  font-size: 1.3em;
  color: var(--color-text-light);
  line-height: 24px;
}

table,
thead,
tbody {
  width: 100%;
}

td,
td > span {
  color: var(--color-text-light);
}

.event-container {
  display: flex;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  mat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    width: 37px;
    height: 37px;
    background-color: #d1d1d1;
  }
}

.mat-mdc-header-cell {
  background-color: var(--color-table-header) !important;
  color: white;
}

.mat-mdc-cell {
  border: none !important;
}

.mat-mdc-header-cell:first-child {
  border-top-left-radius: 10px;
}

.mat-mdc-header-cell:last-child {
  border-top-right-radius: 10px;
}
.category {
  max-width: 100px;
  font-weight: bold;
}
th.small,
td.small {
  max-width: 116px;
  span {
    max-width: 116px;
  }
}

table.clickable {
  .mat-mdc-row:hover {
    cursor: pointer !important;
  }
}

.bold {
  font-weight: 900;
}

tr,
td {
  font-size: 1.1em !important;
  line-height: 22px;
  padding-bottom: 8px;
  padding-top: 8px;
}

mat-form-field {
  width: 100%;
}

.mat-mdc-header-cell {
  background-color: var(--color-table-header) !important;
  color: white;
}
.santander-logo {
  display: flex;
  align-items: center;
}
.id-quota {
  margin-left: 10px;
  display: flex;
  font-weight: 700;
  flex-direction: column;
}

.best-offer {
  font-weight: 900;
  font-size: 1rem;
}
.table-row:hover {
  background-color: #ddd;
}

.table-buttons {
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;

  #btn-clear {
    margin-top: 6px;
  }

  mat-form-field {
    flex: 1 1 250px; /* Garante que cada mat-form-field ocupe o máximo de espaço, mas com largura mínima de 250px */
    margin-right: 10px;
  }
}

.margem-direita {
  margin-right: 20px;
}

.table-title {
  display: flex;
  align-items: center;
  font-size: 0.9em;

  mat-icon {  
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}

.custom-button:hover {
  background-color: rgba(2, 2, 2, 0.04);
}

.custom-button:active {
  background-color: rgba(0, 0, 0, 0.12);
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: 5px 12px 5px 12px;
  border-radius: 18px;
  font-weight: 700;
  min-width: 11em;
}

.encerrado {
  color: white;
  background-color: var(--color-info);
}

.em-andamento {
  background-color: var(--color-warning);
  color: black;
}

.winner {
  background-color: var(--color-success-light);
  color: black;
}

.cursor-pointer {
  cursor: pointer;
}

.danger {
  color: var(--color-danger);
 }
